/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/layout';

function RazerGoldCampaign({ location }) {
  const title = 'Razer Gold Kampanyası';
  const metaData = 'Tıkla Gelsin®’in Razer Gold menülerinden sipariş ver sınırlı sayıdaki Razer Gold’u kap!';

  useEffect(() => {
    const containerTag = document.querySelector('.content-container');
    containerTag.classList.remove('container');
    containerTag.style = 'padding-top: 90px;';
  }, []);

  return (
    <Layout sidebar={false} location={location} hasBreadCrumb>
      <SEO title={title} description={metaData} siteUrl={location?.pathname} />
      <>
        <div className="razer-gold-content-container bg-image">
          <div className="razer-gold-content-wrapper">
            <div className="container special-container">
              <p className="header-text">
                TIKLA GELSiN® iLE <br /> 7 TL’LiK <br /> RAZER GOLD <br /> KAZAN!
              </p>
              <p className="description-text">
                Tıkla Gelsin®’in Razer Gold menülerinden sipariş ver sınırlı sayıdaki Razer Gold’u kap!
              </p>
              <div className="group-logo-image" />
              <div className="razer-divider" />
              <p className="campaign-header">Razer Gold Menü Kampanyaları</p>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-left">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-2" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-right">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-3" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-left">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-1" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-right">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-4" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-left">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-5" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="col-campaign-right">
                  <div className="campaign-image-wrapper">
                    <div className="campaign-image-6" />
                  </div>
                </Col>
              </Row>
              <p className="coupon-header">Nasıl Kupon Kodu Kazanırım?</p>
              <p className="coupon-description">
                9 Mayıs - 9 Temmuz 2022 tarihleri arasında Tıkla Gelsin® Razer Gold menülerde geçerli Razer Gold
                kampanyasından faydalanmaya hak kazanmak için Tıkla Gelsin®’den Razer Gold menü siparişi vermen yeterli.
              </p>
              <Row>
                <Col lg={5} md={12} sm={12} xs={12} className="col-coupon-left">
                  <div className="coupon-image-wrapper">
                    <div className="coupon-image" />
                  </div>
                </Col>
                <Col lg={7} md={12} sm={12} xs={12} className="col-coupon-right">
                  <Row>
                    <div className="step-number-wrapper">
                      <p className="step-number">1</p>
                    </div>
                    <p className="step-header">Sepete ekleme</p>
                    <p className="step-description">
                      Razer Gold menülerinde 7 TL kupon kodu kazan butonunun bulunduğu menüleri sepetine ekle.
                    </p>
                  </Row>
                  <Row>
                    <div className="step-number-wrapper">
                      <p className="step-number">2</p>
                    </div>
                    <p className="step-header">Sipariş ödeme</p>
                    <p className="step-description">
                      Sepete eklediğin Razer Gold menülerinin siparişlerinin adresini girerek ödemesini tamamla.
                    </p>
                  </Row>
                  <Row>
                    <div className="step-number-wrapper">
                      <p className="step-number">3</p>
                    </div>
                    <p className="step-header">SMS ile kod gönderimi</p>
                    <p className="step-description">
                      Sipariş teslim edildikten 3 saat sonra, kayıtlı cep telefonuna SMS ile kod gönderilecektir.
                      https://gold.razer.com/ ‘da geçerli kupon kodunu kopyala.
                    </p>
                  </Row>
                </Col>
              </Row>
              <p className="use-coupon-header">Kupon Kodunu Nasıl Kullanabilirim?</p>
              <Row>
                <div className="use-step-number-wrapper">
                  <p className="step-number">1</p>
                </div>
                <p className="use-step-header">Giriş yap ya da üye ol</p>
              </Row>
              <p className="use-step-description">
                Razer Gold'a üye değilsen https://gold.razer.com/ üzerinden üye ol.
              </p>
              <Row>
                <div className="use-step-number-wrapper">
                  <p className="step-number">2</p>
                </div>
                <p className="use-step-header">SMS ile gelen kodu kopyala</p>
              </Row>
              <p className="use-step-description">
                Tıkla Gelsin'de Razer Gold Menü siparişi verdiğinde SMS ile gelen kodu kopyala.
              </p>
              <Row>
                <div className="use-step-number-wrapper">
                  <p className="step-number">3</p>
                </div>
                <p className="use-step-header">Kupon kodunu yükle</p>
              </Row>
              <p className="use-step-description">
                Kopyaladığın kodu https://gold.razer.com/ 'da kullanarak Razer Gold PIN'ini yükle.
              </p>
              <p className="legal-header">Yasal Metin</p>
              <p className="legal-description">
                Bu kampanya ATA EXPRESS ELEKTRONİK İLETİŞİM TANITIM PAZARLAMA adına MPİ’nin 18.04.2022 tarih ve
                E-24951361-255.05.02-19093 sayılı izni ile Hedef Çekiliş tarafından kapsam dışı olarak düzenlenmiştir.
                09.05.2022 saat 00:01- 09.07.2022 saat 23:59 Kampanya tarihleri arasında Türkiye genelinde (*Ardahan,
                *Bayburt, *Bitlis, *Burdur, *Gümüşhane, *Hakkâri, *Karaman, *Kilis, *Muş, *Tunceli illeri hariç) Tıkla
                Gelsin uygulamasını IOS veya Android telefonlarına indirip (ücretsiz) veya www.tiklagelsin.com
                (ücretsiz) internet sitesi üzerinden ad-soyad-doğum tarihi adres-telefon bilgileriyle üye olduktan
                sonra, Sana Gelsin Fonksiyonu üzerinden Burger King, Popeyes, Arby’s, Sbarro, Usta Dönerci, Usta
                Pideci'ye ait 64,99 TL değerinde Razer Gold menü ürünlerinden sipariş vererek satın alım yapan herkese 7
                TL değerinde Razer Gold kod ikramiye verilecektir. Kampanya sadece bireysel müşterilere yöneliktir.
                Tıkla Gelsin uygulamasını indirmek ve www.tiklagelsin.com internet sitesine giriş ücretsizdir. ATA
                EXPRESS ELEKTRONİK İLETİŞİM TANITIM PAZARLAMA, Reklam Üssü Reklam Ajansı Prodüksiyon Dan. Org. San. Tic.
                A.Ş., Burger King, Popeyes, Arby’s, Sbarro, Usta Dönerci, Usta Pideci çalışanları ve Hedef Çekiliş ve
                Organizasyon Ltd. Şti. çalışanları ile 18 yaşından küçük olanlar düzenlenen piyango ve çekilişe
                katılamaz, katılmış ve kazanmış olsalar da bu ikramiyeler verilmez. İkramiyeye konu olan eşya ve/veya
                hizmetin bedeli içinde bulunan KDV+ÖTV gibi vergiler dışındaki vergi ve diğer yasal yükümlülükler
                talihliler tarafından ödenir.
              </p>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}

RazerGoldCampaign.propTypes = {
  location: PropTypes.object,
};

export default RazerGoldCampaign;
